import React from "react";

function TransparentLogo() {
  return (
    <svg
      width="455"
      height="225"
      viewBox="0 0 455 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M230.517 85.1512H158.23V100.165H230.517V85.1512Z"
        fill="#818181"
      />
      <path
        d="M158.23 85.1512H85.9561V100.165H158.23V85.1512Z"
        fill="#818181"
      />
      <path
        d="M158.23 209.986V225L79.2506 96.5245V81.5107L158.23 209.986Z"
        fill="#808080"
      />
      <path
        d="M237.235 81.5107V96.5245L158.23 225V209.986L237.235 81.5107Z"
        fill="#919191"
      />
      <path
        d="M79.2506 81.5107H237.235L158.23 209.986L79.2506 81.5107ZM85.956 85.2028L158.23 202.679L230.517 85.2028H85.956Z"
        fill="#DFDFDF"
      />
      <path
        d="M151.279 124.822V139.836L79.0051 22.2818V7.26807L151.279 124.822Z"
        fill="#808080"
      />
      <path
        d="M79.0052 7.26807V22.2818L6.70544 139.836V124.822L79.0052 7.26807Z"
        fill="#919191"
      />
      <path d="M157.984 128.476H0V143.489H157.984V128.476Z" fill="#818181" />
      <path
        d="M79.0052 0L157.984 128.476H0L79.0052 0ZM6.65375 124.822H151.227L79.0052 7.26806L6.65375 124.822Z"
        fill="#DFDFDF"
      />
      <path
        d="M170.775 85.1125H95.1292V99.2355H170.775V85.1125Z"
        fill="#818181"
      />
      <path
        d="M170.775 81.472H95.1292V85.1125H170.775V81.472Z"
        fill="#DFDFDF"
      />
      <path
        d="M135.543 99.2355H140.026L140.62 100.178L136.137 100.23"
        fill="#818080"
      />
      <path
        d="M246.059 56.2339H254.16C263.488 56.2339 268.992 61.2557 268.992 71.3122C268.992 81.3687 263.488 86.6229 254.444 86.6229H246.059V56.2339ZM253.811 82.2595C259.935 82.2595 263.45 78.7739 263.45 71.3122C263.45 63.8505 259.935 60.5973 253.811 60.5973H251.447V82.2595H253.811Z"
        fill="#F6F6F6"
      />
      <path
        d="M274.974 73.3003V56.2339H280.362V73.7005C280.362 80.323 282.829 82.4918 286.512 82.4918C290.194 82.4918 292.804 80.323 292.804 73.7005V56.2339H297.972V73.3003C297.972 83.2793 293.54 87.178 286.486 87.178C279.431 87.178 274.974 83.2793 274.974 73.3003Z"
        fill="#F6F6F6"
      />
      <path
        d="M303.036 82.7242L306.202 79.0321C308.316 81.1333 311.142 82.3676 314.121 82.4918C317.545 82.4918 319.419 80.9427 319.419 78.619C319.419 76.2953 317.455 75.3141 314.703 74.1394L310.53 72.2933C307.532 71.0024 304.38 68.6786 304.38 64.2378C304.38 59.3838 308.656 55.6658 314.587 55.6658C318.102 55.668 321.475 57.0603 323.966 59.5387L321.202 62.9597C319.395 61.3067 317.036 60.3862 314.587 60.3778C311.705 60.3778 309.832 61.6688 309.832 63.9409C309.832 66.2129 312.145 67.207 314.664 68.2397L318.773 69.9696C322.39 71.4671 324.922 73.7521 324.922 78.1671C324.922 83.0857 320.827 87.2038 313.992 87.2038C309.895 87.1992 305.962 85.5912 303.036 82.7242Z"
        fill="#F6F6F6"
      />
      <path
        d="M330.478 56.2339H335.917V70.0471H336.072L346.899 56.2339H352.881L343.527 68.0977L354.47 86.6229H348.514L340.233 72.2933L335.917 77.754V86.6229H330.53L330.478 56.2339Z"
        fill="#F6F6F6"
      />
      <path
        d="M261.098 123.312L264.251 119.607C266.361 121.716 269.188 122.955 272.17 123.079C275.594 123.079 277.468 121.53 277.468 119.207C277.468 116.883 275.504 115.915 272.752 114.74L268.592 112.932C265.594 111.642 262.442 109.318 262.442 104.877C262.442 100.036 266.718 96.305 272.649 96.305C276.163 96.3128 279.533 97.7043 282.028 100.178L279.264 103.599C277.457 101.946 275.098 101.025 272.649 101.017C269.767 101.017 267.894 102.308 267.894 104.593C267.894 106.878 270.207 107.859 272.726 108.892L276.835 110.609C280.452 112.119 282.972 114.404 282.972 118.806C282.972 123.738 278.889 127.843 272.054 127.843C267.95 127.822 264.017 126.195 261.098 123.312Z"
        fill="#F6F6F6"
      />
      <path
        d="M288.54 96.8214H306.835V101.366H293.915V109.111H304.91V113.642H293.928V122.679H307.3V127.223H288.54V96.8214Z"
        fill="#F6F6F6"
      />
      <path
        d="M311.951 112.106C311.951 102.166 318.191 96.2534 325.892 96.2534C327.539 96.268 329.166 96.6192 330.672 97.2852C332.178 97.9512 333.532 98.918 334.651 100.126L331.77 103.612C331.037 102.811 330.148 102.168 329.157 101.724C328.166 101.28 327.094 101.044 326.008 101.03C321.047 101.03 317.494 105.213 317.494 112.029C317.494 118.845 320.775 123.17 325.866 123.17C327.104 123.145 328.323 122.853 329.438 122.314C330.553 121.775 331.538 121.001 332.326 120.046L335.207 123.428C334.047 124.825 332.588 125.943 330.938 126.701C329.287 127.458 327.488 127.835 325.672 127.804C318.023 127.804 311.951 122.163 311.951 112.106Z"
        fill="#F6F6F6"
      />
      <path
        d="M339.457 113.875V96.8214H344.845V114.288C344.845 120.898 347.325 123.079 351.008 123.079C354.69 123.079 357.287 120.898 357.287 114.288V96.8214H362.455V113.875C362.455 123.854 358.036 127.766 350.982 127.766C343.928 127.766 339.457 123.854 339.457 113.875Z"
        fill="#F6F6F6"
      />
      <path
        d="M370.065 96.8214H380.323C386.641 96.8214 391.512 99.0547 391.512 105.858C391.512 112.39 386.641 115.359 380.323 115.359H375.465V127.236H370.065V96.8214ZM379.754 111.022C383.928 111.022 386.214 109.305 386.214 105.858C386.214 102.411 383.941 101.159 379.754 101.159H375.465V111.022H379.754ZM379.302 113.901L383.178 110.686L392.765 127.21H386.705L379.302 113.901Z"
        fill="#F6F6F6"
      />
      <path
        d="M397.597 96.8214H402.997V127.21H397.597V96.8214Z"
        fill="#F6F6F6"
      />
      <path
        d="M416.86 101.353H408.075V96.8214H431.034V101.366H422.248V127.185H416.86V101.353Z"
        fill="#F6F6F6"
      />
      <path
        d="M440.452 115.902L431.292 96.8214H437.067L440.375 104.567C441.266 106.826 442.119 108.943 443.075 111.254H443.269C444.225 108.943 445.181 106.826 446.059 104.567L449.38 96.8214H455L445.84 115.915V127.223H440.452V115.902Z"
        fill="#F6F6F6"
      />
    </svg>
  );
}


export default TransparentLogo;