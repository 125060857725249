import React from "react";
import { Link } from "gatsby";
import Navbar from "../components/navbar";
import Seo from "../components/seo";
import TransparentLogo from "../components/SVG/transparent";
import { HiArrowRight } from "react-icons/hi";

function IndexPage() {
  const txtStyles = {
    textShadow: "-3px 0px 0px #4E4E50",
  };

  return (
    <>
      <Seo />
      <Navbar />
      <div className="flex absolute w-full h-3/4 justify-center items-center lg:flex-row flex-col overflow-hidden">
        <div className="flex justify-start lg:w-1/2 w-3/4 items-center h-80 lg:h-1/3 h-3/5">
          <TransparentLogo />
        </div>
        <div className="flex flex-col lg:w-2/5 w-full pl-3">
          <div
            className="flex text-5xl text-white m-2 leading-normal"
            style={txtStyles}
          >
            Security against obscured loopholes
          </div>
          <div className="flex lg:justify-start justify-center flex-row w-full">
            <Link to="/contactus">
              <button className="ml-2 text-2xl bg-red text-white p-2 hover:shadow rounded">
                Get a Quote
              </button>
            </Link>
            <Link
              to="/aboutus"
              className="flex ml-4 text-2xl text-white flex-row items-center"
            >
              <div className="underline">About Us</div>
              <HiArrowRight className="mt-2 ml-1" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndexPage;
